var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardBody',[_c('CDataTable',{ref:"vuetable",attrs:{"items":_vm.items,"fields":_vm.fields,"striped":"","clickable-rows":"","column-filter":"","table-filter":"","items-per-page":_vm.itemsPerPage,"sorter":"","pagination":""},on:{"page-change":_vm.pagechange,"pages-change":_vm.pageschange},scopedSlots:_vm._u([{key:"firstName-filter",fn:function(){return _vm._l((_vm.filter),function(item){return _c('div',{key:item},[_vm._v(" "+_vm._s(item.firstName)+_vm._s(item.lastName)+" ")])})},proxy:true},{key:"status-filter",fn:function(){return [_c('select',{staticClass:"form-control form-control-sm",on:{"input":function($event){return _vm.$refs.vuetable.columnFilterEvent(
              'status',
              $event.target.value,
              'input'
            )}}},[_c('option',{attrs:{"value":"","selected":"selected"}},[_vm._v("Any")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Not Completed")]),_c('option',{attrs:{"value":"2"}},[_vm._v("In MidWay")]),_c('option',{attrs:{"value":"3"}},[_vm._v("Completed")])])]},proxy:true},{key:"index",fn:function(data){return [_c('td',[_vm._v(" "+_vm._s(data.index + 1)+" ")])]}},{key:"createdTime",fn:function({ item }){return [(item.createdTime)?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm._f("dateParse")(item.createdTime),"MMM D, YYYY hh:mm A"))+" ")]):_c('td',[_vm._v("--")])]}},{key:"firstName",fn:function({ item }){return [(item.firstName && item.lastName)?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.firstName)+_vm._s(item.lastName)+" ")]):_c('td',[_vm._v("--")])]}},{key:"noOfApproval",fn:function({ item }){return [(item.noOfApproval)?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.noOfApproval)+" ")]):_c('td',[_vm._v("--")])]}},{key:"status",fn:function({ item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item.status == 0),expression:"item.status == 0"}],staticClass:"font-weight-bold"},[_vm._v("Nothing")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(item.status == 1),expression:"item.status == 1"}],staticClass:"font-weight-bold"},[_vm._v(" Not Completed ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(item.status == 2),expression:"item.status == 2"}],staticClass:"font-weight-bold"},[_vm._v("In MidWay")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(item.status == 3),expression:"item.status == 3"}],staticClass:"font-weight-bold"},[_vm._v("Completed")])]}},{key:"show_details",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[_c('CDropdown',{attrs:{"togglerText":"Actions","color":"info"}},[_c('CDropdownItem',{attrs:{"to":{
                name: 'PermitOfficers',
                params: { requestId: item.requestId },
              }}},[_vm._v("Assigned Officers ")]),_c('CDropdownItem',{attrs:{"to":{
                name: 'SectionFormData',
                params: {
                  sectionFormId: item.sectionFormId,
                  requestId: item.requestId,
                  mode: 'view',
                },
              }}},[_vm._v("Sections ")]),(item.status == 3)?_c('CDropdownItem',{on:{"click":function($event){return _vm.navToExport(item.requestId)}}},[_vm._v(" Export")]):_vm._e()],1)],1)]}}])})],1),_c('div',{ref:"modalArea"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }