<template>
  <CCard>
    <CCardBody>
      <CDataTable
        :items="items"
        :fields="fields"
        striped
        clickable-rows
        column-filter
        table-filter
        :items-per-page="itemsPerPage"
        @page-change="pagechange"
        @pages-change="pageschange"
        sorter
        ref="vuetable"
        pagination
      >
        <template #firstName-filter>
          <!-- <input v-modal="filteredList" id="search" type="search" /> -->
          <div v-for="item in filter" :key="item">
            {{ item.firstName }}{{ item.lastName }}
          </div>
        </template>
        <template #status-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'status',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Not Completed</option>
            <option value="2">In MidWay</option>
            <option value="3">Completed</option>
          </select>
        </template>

        <template #index="data">
          <td>
            {{ data.index + 1 }}
          </td>
        </template>
        <template #createdTime="{ item }">
          <td class="font-weight-bold" v-if="item.createdTime">
            {{
              item.createdTime | dateParse() | dateFormat("MMM D, YYYY hh:mm A")
            }}
          </td>
          <td v-else>--</td>
        </template>
        <template #firstName="{ item }">
          <!-- <td class="font-weight-bold" v-if="item.firstName && item.lastName">
            {{ item.firstName }}{{ item.lastName }}
          </td>
          <td v-else>--</td> -->
          <!-- <input v-modal="filteredList" id="search" type="search" required /> -->
          <!-- <div v-for="item in filteredList" :key="item"> -->
          <td class="font-weight-bold" v-if="item.firstName && item.lastName">
            {{ item.firstName }}{{ item.lastName }}
          </td>
          <td v-else>--</td>
          <!-- </div> -->
        </template>
        <template #noOfApproval="{ item }">
          <td class="font-weight-bold" v-if="item.noOfApproval">
            {{ item.noOfApproval }}
          </td>
          <td v-else>--</td>
        </template>
        <template #status="{ item }">
          <td class="font-weight-bold" v-show="item.status == 0">Nothing</td>
          <td class="font-weight-bold" v-show="item.status == 1">
            Not Completed
          </td>
          <td class="font-weight-bold" v-show="item.status == 2">In MidWay</td>
          <td class="font-weight-bold" v-show="item.status == 3">Completed</td>
        </template>
        <template #show_details="{ item }">
          <td class="py-2">
            <CDropdown togglerText="Actions" color="info">
              <CDropdownItem
                :to="{
                  name: 'PermitOfficers',
                  params: { requestId: item.requestId },
                }"
                >Assigned Officers
              </CDropdownItem>
              <CDropdownItem
                :to="{
                  name: 'SectionFormData',
                  params: {
                    sectionFormId: item.sectionFormId,
                    requestId: item.requestId,
                    mode: 'view',
                  },
                }"
                >Sections
              </CDropdownItem>

              <CDropdownItem
                v-if="item.status == 3"
                @click="navToExport(item.requestId)"
              >
                Export</CDropdownItem
              >
            </CDropdown>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <div ref="modalArea"></div>
  </CCard>
</template>

<script>
const fields = [
  { key: "index", _classes: "font-weight-bold", _style: "min-width:200px" },
  {
    key: "title",
    label: "Form Title",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "firstName",
    label: "Requested By",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  { key: "status", _classes: "font-weight-bold", _style: "min-width:200px" },
  {
    key: "noOfApproval",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import Vue from "vue";
import ConfirmationModal from "../../containers/ConfirmationModal";

export default {
  name: "WorkPermitRequests",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      paginationLastPage: 0,
      paginatedCurrentPage: 0,
      paginationNextPage: 0,
      itemsPerPage: 20,
      selectedType: "",
      startDate: null,
      endDate: null,
      firstName: "",
      lastName: "",
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
      filteredList: [],
      filterInput: "",
    };
  },
  methods: {
    nextPage() {
      return ++this.paginationNextPage;
    },
    pagechange(activePage) {
      console.log("work permit activePage", activePage);
      if (this.paginationLastPage - activePage < 1) {
        console.log("calling work permit next page");
        this.getWorkPermitRequests(this.nextPage());
      }
    },
    pageschange(totalPages) {
      console.log("totalPages", totalPages);
      this.paginationLastPage = totalPages;
    },
    navToExport(requestId) {
      var filename = "WorkPermitForm.pdf";
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/workPermit/request/pdf/" +
          requestId
      )
        .then((response) => {
          if (response.status === 200) {
            console.log("headers", response.headers);
            // filename = response.headers
            //   .get("Content-Disposition")
            //   .split("filename=")[1];
            // console.log(filename);

            return response.blob();
          } else {
            return;
          }
        })
        .then((blob) => {
          var downloadUrl = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    },
    getWorkPermitRequests() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/work/permit/requests"
      )
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    filter(e) {
      if (e.target.value === "") this.filteredList = this.items;
      else {
        this.filteredList = [];
        this.items.forEach((item) => {
          console.log("item values from filter", item);
          if (
            item.firstName.includes(e.target.value) ||
            item.lastName.includes(e.target.value)
          )
            this.filteredList.push(item);
        });
      }
    },

    // navToNewSymptoms(){
    //    this.$router.push({ name: "WorkPermitRequest", params: { mode: "new" } });
    // }
  },

  mounted() {
    this.filteredList = this.items;
    this.getWorkPermitRequests();
  },
};
</script>